import React from 'react';
import {Helmet} from "react-helmet";
import './App.css';

function App() {
  return (
    <div className="App">
			<Helmet>
				<script type="text/javascript" src="https://cdn.jsdelivr.net/pako/1.0.3/pako.min.js"></script>
				<script type="text/javascript" src="vtx/vtxdata.js"></script>
				<script type="text/javascript" src="vtx/vtxclient.js"></script>
			</Helmet>
      <header className="App-header">
				<div id="vtxclient">

				</div>

			</header>
    </div>
  );
}

export default App;
